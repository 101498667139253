<template>
  <carousel
    :settings="settings"
    :breakpoints="breakpoints"
    :autoplay="2000"
    :wrapAround="true"
  >
    <slide v-for="slide in slides" :key="slide">
      <a target="blank" :href="slide.link">
        <img class="carousel__item" :src="slide.path" alt="" />
      </a>
    </slide>

    <template #addons> </template>
  </carousel>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";

export default {
  name: "App",
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      slides: [
        {
          name: "cisco",
          path: "/assets/partners/cisco.png",
          link: "https://cisco.com",
        },
        {
          name: "foneo",
          path: "/assets/partners/foneo.png",
          link: "https://foneo.pl",
        },
        {
          name: "hik",
          path: "/assets/partners/hik.png",
          link: "https://www.hikvision.com/pl/",
        },
        {
          name: "mikrotik",
          path: "/assets/partners/mikro.png",
          link: "https://mikrotik.com/",
        },
        {
          name: "satel",
          path: "/assets/partners/satel.png",
          link: "https://satel.pl",
        },
        {
          name: "slican",
          path: "/assets/partners/slican.png",
          link: "https://www.slican.pl/",
        },
        {
          name: "ubiquiti",
          path: "/assets/partners/ubiquiti.png",
          link: "https://ui.com/eu?gclid=CjwKCAjwvdajBhBEEiwAeMh1U-ecpvMvqq3416ZK13NFknhW1uRpsFz_vF0K4pWHSqFqGqV8dcOybxoCIfMQAvD_BwE",
        },
      ],
      settings: {
        itemsToShow: 1,
        snapAlign: "left",
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 3.5,
          snapAlign: "left",
        },
        // 1024 and up
        1024: {
          itemsToShow: 5,
          snapAlign: "left",
        },
      },
    };
  },
};
</script>

<style scoped>
.carousel__item {
  width: 100px;
}
.carousel__slide > .carousel__item {
  transform: scale(1);
  opacity: 0.5;
  transition: 0.5s;
}
.carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
}
.carousel__slide--next > .carousel__item {
  transform: scale(0.9) translate(-10px);
}
.carousel__slide--prev > .carousel__item {
  transform: scale(0.9) translate(10px);
}
.carousel__slide--active > .carousel__item {
  transform: scale(1.3);
}
</style>
