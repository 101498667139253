<template>
  <div :class="{ toggle: isOpen }" @click="toggle" class="burger">
    <span :style="{ backgroundColor: color }" class="line line1"></span>
    <span :style="{ backgroundColor: color }" class="line line2"></span>
    <span :style="{ backgroundColor: color }" class="line line3"></span>
  </div>
</template>

<script>
export default {
  props: ["isOpen", "color", "size"],
};
</script>

<style scoped>
.burger {
  width: 30px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  transition: 0.3s all;
  cursor: pointer;
  z-index: 200;
}
.line {
  transition: 0.3s all;
  width: 100%;
  height: 4px;
  background-color: black;
  border-radius: 100px;
}
.line2 {
  transform: translateX(10px);
  background: linear-gradient(90deg, #023e8a, #0077b6) !important;
}
.burger.toggle span:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
  transition-delay: 0.125s;
}
.burger.toggle span:nth-child(2) {
  transform: translateX(150px);
}
.burger.toggle span:nth-child(3) {
  transform: translateY(-8px) rotate(315deg);
  transition-delay: 0.25s;
}
</style>
