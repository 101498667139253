<template>
  <div class="wrapper">
    <transition name="cookies">
      <the-cookie-pop-up> </the-cookie-pop-up>
    </transition>
    <the-header></the-header>
    <router-view v-slot="slotProps">
      <transition name="route" mode="out-in">
        <component :is="slotProps.Component"></component>
      </transition>
    </router-view>
  </div>
</template>

<script>
import TheCookiePopUp from "./components/UI/TheCookiePopUp.vue";

export default {
  components: { TheCookiePopUp },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,500&display=swap");
* {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
* {
  scrollbar-width: normal;
  scrollbar-color: rgb(0, 0, 0);
}
*::-webkit-scrollbar {
  width: 0.5vw;
}
*::-webkit-scrollbar-thumb {
  background-color: #023e8a;
  border-radius: 20px;
}
*::-webkit-scrollbar-track {
  background-color: transparent;
  border-left: transparent;
}
body {
  background: linear-gradient(90deg, #023e8a, #0077b6);
}
.wrapper {
  overflow-x: hidden;
}
.route-enter-active {
  transition: all 0.3s ease-out;
}
.route-enter-to,
.route-leave-from {
  opacity: 1;
}
.route-leave-active {
  transition: opacity 0.3s ease-out;
}
.route-leave-to,
.route-enter-from {
  opacity: 0;
}
.cookies-enter-active {
  transition: all 0.3s ease-out;
}
.cookies-enter-to,
.cookies-leave-from {
  opacity: 1;
  transform: translateY(0) scale(1);
}
.cookies-leave-active {
  transition: all 0.3s ease-out;
}
.cookies-leave-to,
.cookies-enter-from {
  opacity: 0;
  transform: translateY(-200px) scale(0.7);
}
</style>
