<template>
  <div class="footer__Section">
    <h1>Copyright Waltel 2022</h1>
    <h1>PoweredBy NiCode.pl</h1>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.footer__Section {
  height: 50px;
  width: 100%;
  background-color: hsla(0, 0%, 100%, 0.6);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
}
h1 {
  color: white;
  font-size: 1rem;
  font-weight: 300;
}
@media screen and (max-width: 600px) {
  h1 {
    color: white;
    font-size: 0.8rem;
    font-weight: 300;
  }
}
</style>
