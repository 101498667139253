<template>
  <div>
    <div class="main__Section">
      <home-section
        v-if="selectedBtn.id === 1"
        :h1="selectedBtn.h1"
        :h2="selectedBtn.h2"
        :imgSrc="selectedBtn.imgSrc"
        :link="selectedBtn.link"
      ></home-section>
      <home-section
        v-else-if="selectedBtn.id === 2"
        :h1="selectedBtn.h1"
        :h2="selectedBtn.h2"
        :imgSrc="selectedBtn.imgSrc"
        :link="selectedBtn.link"
      ></home-section>
      <home-section
        v-else-if="selectedBtn.id === 3"
        :h1="selectedBtn.h1"
        :h2="selectedBtn.h2"
        :imgSrc="selectedBtn.imgSrc"
        :link="selectedBtn.link"
      ></home-section>
      <home-section
        v-else-if="selectedBtn.id === 4"
        :h1="selectedBtn.h1"
        :h2="selectedBtn.h2"
        :imgSrc="selectedBtn.imgSrc"
        :link="selectedBtn.link"
      ></home-section>
      <div class="main__Section__Nav">
        <btn-btn
          @click="changeSlide(button)"
          v-for="button in buttons"
          :key="button.id"
        ></btn-btn>
      </div>
    </div>
    <div class="partners__Section">
      <partner-section></partner-section>
    </div>
    <div class="footer">
      <the-footer></the-footer>
    </div>
  </div>
</template>

<script>
import HomeSection from "./homeSection/homeSection.vue";
import PartnerSection from "./homeSection/partnersSection.vue";
export default {
  components: {
    HomeSection,
    PartnerSection,
  },
  data() {
    return {
      selectedBtn: {
        id: 1,
        h1: "Serwery telekomunikacyjne",
        h2: "Firma od lat ’80 zajmuje się branżą telekomunikacyjną, która wraz z biegiem lat prężnie się rozwijała",
        imgSrc: "/assets/Home/outsourcing.png",
        link: "#box1",
      },
      buttons: [
        {
          id: 1,
          h1: "Serwery telekomunikacyjne",
          h2: "Firma od lat ’80 zajmuje się branżą telekomunikacyjną, która wraz z biegiem lat prężnie się rozwijała",
          imgSrc: "/assets/Home/outsourcing.png",
          link: "#box1",
        },
        {
          id: 2,
          h1: "Systemy monitoringu CCTV",
          h2: "Zajmujemy się montażem monitoringów analogowych oraz cyfrowych IP.",
          imgSrc: "/assets/Home/monitoringSieci.png",
          link: "#box2",
        },
        {
          id: 3,
          h1: "Systemy SSWiN i KD",
          h2: "Wykonujemy instalację systemów SSWiN i KD w technologi przewodowej lub bezprzewodowej.",
          imgSrc: "/assets/Home/rodo2.png",
          link: "#box3",
        },
        {
          id: 4,
          h1: "Sieci teleinformatyczne",
          h2: "Projektowanie i realizacja sieci komputerowych, rozwiązań sprzętowych, dobieranie urządzeń dla firmy, biura oraz domu.",
          imgSrc: "/assets/Home/cloud.png",
          link: "#box4",
        },
      ],
    };
  },
  methods: {
    changeSlide(button) {
      this.selectedBtn = button;
    },
  },
};
</script>

<style scoped>
.main__Section {
  position: relative;
  overflow: hidden;
}
.main__Section__Nav {
  overflow: visible;
  position: absolute;
  bottom: 50px;
  left: calc(50% - 60px);
  display: flex;
  align-content: center;
  justify-content: space-around;
  width: 120px;
  height: 20px;
}
.partners__Section {
  background-color: white;
}
</style>
