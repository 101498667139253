<template>
  <div>
    <div class="oferta__Section">
      <single-oferta
        :id="'box1'"
        :heading="'Serwery telekomunikacyjne w technologiach: cyfrowej, hybrydowej, pure-IP i analogowej'"
        :content="'Firma od lat ’80 zajmuje się branżą telekomunikacyjną, która wraz z biegiem lat prężnie się rozwijała, dzięki bogatemu doświadczeniu dziś możemy zaoferować każdy rodzaj usług – od serwisu, montażu i obsługi tradycyjnej technologii TDM aż po nowoczesne redundantne systemy PureIP'"
      ></single-oferta>
      <single-oferta
        :id="'box2'"
        :heading="'Systemy monitoringu CCTV'"
        :content="'Zajmujemy się montażem monitoringów analogowych oraz cyfrowych IP. Instalujemy sprzęt światowych liderów na rynku tj. HIKVISION czy BCS. Oferujemy montaż oraz integracje kamer LPR do systemów alarmowych czy też KD.'"
      ></single-oferta>
      <single-oferta
        :id="'box3'"
        :heading="'Systemy alarmowe, kontroli dostępu oraz rejestracji czasu pracy - automatyka'"
        :content="'Wykonujemy instalację systemów SSWiN i KD w technologi przewodowej lub bezprzewodowej. Jesteśmy w stanie sprostać najbardziej zaawansowanym zadaniom z tej dziedziny. Specjalnie dla każdego klienta przygotujemy plan systemu oraz dobieramy odpowiednie urządzenia według potrzeb i wymagań.'"
      ></single-oferta>
      <single-oferta
        :id="'box4'"
        :heading="'Sieci teleinformatyczne'"
        :content="'Projektowanie i realizacja sieci komputerowych, rozwiązań sprzętowych, dobieranie urządzeń dla firmy, biura oraz domu.'"
      ></single-oferta>
      <single-oferta
        :id="'box5'"
        :heading="'Serwery - w tym środowiska wirtualizacji'"
        :content="'Czasem sieci komputerowe to za mało! Doradzamy, wdrażamy oraz opiekujemy się środowiskami serwerowymi począwszy od serwerów fizycznych po zaawansowane rozwiązania chmurowe.'"
      ></single-oferta>
      <single-oferta
        :id="'box6'"
        :heading="'Instalacje elektryczne do 1kV'"
        :content="'Instalacje biurowe, sklepy, magazyny czy hale przemysłowe - doświadczenie pomoże nam skutecznie zrealizwować każdy projekt. Oferujemy również pomiary i testy instalacji elektrycznych.'"
      ></single-oferta>
    </div>
    <div class="navigation">
      <slot>
        <side-nav></side-nav>
      </slot>
    </div>
  </div>
</template>

<script>
import SingleOferta from "./ofertaSection/ofertaBox.vue";
import SideNav from "../UI/sideNav.vue";
export default {
  components: {
    SingleOferta,
    SideNav,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.oferta__Section {
  top: 0;
  width: 100%;
  height: calc(100vh - 141px);
  transition: 0.5s all;
  color: white;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth !important;
}
@media screen and (max-width: 1180px) {
  .oferta__Section {
    height: calc(100vh - 104px);
  }
}
.navigation {
  position: fixed;
  z-index: 322;
}
@media screen and (max-width: 1180px) {
  .navigation {
    position: fixed;
    height: calc(100vh - 104px);
  }
}
</style>
