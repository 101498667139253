<template>
  <div class="navContainer">
    <nav class="tools flex">
      <h1 class="blue">Rozwiązania dla Twojego biznesu</h1>
      <ul class="nav__tools">
        <li>
          <a href="mailto:sekretariat@waltel.pl"
            ><font-awesome-icon icon="fa-solid fa-envelope" />
            sekretariat@waltel.pl</a
          >
        </li>
        <li>
          <a href="callto:585065161"
            ><font-awesome-icon icon="fa-solid fa-mobile-screen-button" /> 58
            506 51 61</a
          >
        </li>
        <li>
          <a href="http://www.waltel.pl/Programy/Waltel_pomoc.exe"
            ><font-awesome-icon icon="fa-solid fa-display" /> Pomoc zdalna</a
          >
        </li>
        <a
          target="blank"
          href="https://www.facebook.com/p/Waltel-ZUT-100054516156939/"
        >
          <font-awesome-icon class="fb" :icon="['fab', 'facebook']" />
        </a>
      </ul>
    </nav>
    <nav class="flex">
      <router-link to="/">
        <div class="logo">
          <img src="/assets/waltel2.png" alt="" />
        </div>
      </router-link>
      <ul v-if="forDesktop()" class="nav__links">
        <router-link to="/"><li>Strona główna</li></router-link>
        <router-link to="/oferta"><li>Oferta</li></router-link>
        <router-link to="/kontakt"><li>Kontakt</li></router-link>
      </ul>
      <ul v-if="!forDesktop()" class="nav__links2">
        <router-link @click="NavHandler" to="/"
          ><li>Strona główna</li></router-link
        >
        <router-link @click="NavHandler" to="/oferta"
          ><li>Oferta</li></router-link
        >
        <router-link @click="NavHandler" to="/kontakt"
          ><li>Kontakt</li></router-link
        >
      </ul>
      <the-burger
        :isOpen="isOpen"
        v-if="!forDesktop()"
        @click="NavHandler"
      ></the-burger>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      isOpen: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.forDesktop);
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.forDesktop);
  },
  methods: {
    forDesktop() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 980) {
        return false;
      } else {
        return true;
      }
    },
    NavHandler() {
      const navLinks = document.querySelector("ul.nav__links2");
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        navLinks.style.transform = "translateX(0%)";
      } else {
        navLinks.style.transform = "translateX(100%)";
      }
    },
  },
};
</script>

<style scoped>
.fb {
  font-size: 1.2rem;
  transition: 0.3s all;
}
a {
  text-decoration: none;
  color: inherit;
}

.navContainer {
  top: 0;
  width: 100%;
  z-index: 350;
  background-color: white;
}
nav.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 100px;
}
ul.nav__links {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 40%;
  transition: 0.3s all;
}
ul.nav__links2 {
  top: 104px;
  padding-bottom: 150px;
  position: fixed;
  left: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  transition: 0.3s all;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 350;
  transform: translateX(100%);
}
ul.nav__links2 li {
  font-size: 1.5rem;
}
.nav__links li {
  cursor: pointer;
  transition: 0.5s all;
  font-weight: 400;
  font-size: 1.1rem;
}
.nav__links li:hover,
.fb:hover {
  color: #023e8a;
}
nav.tools {
  padding: 10px 30px;
  border-bottom: 1px #c0c0c0 solid;
}
nav.tools {
  font-size: 0.8rem;
  padding: 10px 30px;
}
nav.tools h1 {
  font-weight: 300;
  color: #0077b6;
  font-size: 1rem;
}
.nav__tools {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  width: 35%;
}
.nav__tools a {
  text-decoration: none;
  color: #000000;
}
.nav__tools li {
  transition: 0.3s all;
  min-width: 100px;
}
.nav__tools li:hover,
.fb:hover {
  transform: scale(1.1);
}
a.router-link-active {
  color: #023e8a;
  font-size: 1.2rem;
}
svg {
  color: #0077b6;
}

@media screen and (max-width: 1180px) {
  .tools {
    display: none !important;
  }
  ul.nav__links {
    width: 50%;
  }
}
@media screen and (max-width: 980px) {
  nav.flex {
    padding: 5px 50px;
  }
  .logo img {
    width: 200px;
    padding: 1rem;
  }
}
</style>
