<template>
  <div class="box" :style="image">
    <div class="textBox">
      <h1>{{ heading }}</h1>
      <div class="line"></div>
      <h2>{{ content }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  props: ["heading", "content"],
  data() {},
};
</script>

<style scoped>
.box {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 80vh;
  background-blend-mode: multiply;
  scroll-snap-align: start;
  transition: all 0.4s;
  display: flex;
  align-items: center;
  justify-content: left;
}
.textBox {
  color: #fff;
  width: 100%;
  min-height: 40vh;
  background-color: #023e8a;
  padding: 4% 5%;
  z-index: 10;
}
.line {
  width: 50px;
  height: 3px;
  margin: 2rem 0;
  background-color: white;
}
h1 {
  font-weight: 600;
  font-size: 1.6rem;
  max-width: 40%;
}
h2 {
  font-weight: 300;
  max-width: 40%;
  font-size: 1rem;
  text-align: justify;
}
@media screen and (max-width: 1180px) {
  .box {
    height: calc(100vh - 104px);
  }
  h1 {
    font-weight: 400;
    font-size: 2rem;
    max-width: 80%;
  }
  h2 {
    font-weight: 300;
    font-size: 1.3rem;
    max-width: 80%;
  }
}
@media screen and (max-width: 880px) {
  .box {
    height: calc(100vh - 104px);
    padding: 0.7rem;
  }

  h1 {
    font-weight: 400;
    font-size: 1.5rem;
  }
  h2 {
    font-weight: 300;
    font-size: 1rem;
  }
  .textBox::before {
    width: 0.3rem;
  }
}
</style>
