<template>
  <section class="kontakt__Section">
    <div class="descKontakt kontktBox">
      <div class="desc1">
        <h1>WALTEL</h1>
        <ul>
          <li>Zakład Usług Telekomunikacyjnych</li>
          <li>Waldemar Kurpet</li>
          <br />

          <li>ul. Wałowa 28/L6</li>
          <li>84-200 Wejherowo</li>
          <br />

          <li>NIP: 5881137640</li>
          <li>Tel: 58 506 -51-61</li>
          <li>
            <a href="mailto:sekretariat@waltel.pl"
              >Email: sekretariat@waltel.pl</a
            >
          </li>
        </ul>
      </div>
      <div class="desc2">
        <h1>Szybka Pomoc</h1>
        <ul>
          <li>ul. Wałowa 28/L6</li>
          <li>84-200 Wejherowo</li>
          <br />

          <li>NIP: 5881137640</li>
          <li>Tel: 58 506 -51-61</li>
          <li>
            <a href="mailto:support@waltel.pl">Email: support@waltel.pl</a>
          </li>
          <br />
          <li>
            Zdalna pomoc:
            <a href="http://www.waltel.pl/Programy/Waltel_pomoc.exe"
              >pobierz program</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="mapa kontktBox">
      <iframe
        class="mapaFrame"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2311.0849555218997!2d18.239939176784997!3d54.602495979999205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fdba2e733dce6b%3A0x68d5e963a42cbd09!2sWALTEL!5e0!3m2!1spl!2spl!4v1685444723137!5m2!1spl!2spl"
        width="100%"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  </section>
</template>

<style scoped>
a {
  text-decoration: none;
  color: lightgrey;
}
.kontakt__Section {
  width: 100%;
  height: calc(100vh - 140px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  position: relative;
  flex-wrap: wrap;
  overflow-x: hidden;
}
.kontakt__Section h1 {
  margin-bottom: 1rem;
  animation: h1h2 1.5s forwards;
  animation-delay: 0.5s;
  transform: translateX(-200%);
}

.descKontakt {
  position: relative;
}
.kontakt__Section .desc1,
.kontakt__Section .desc2 {
  color: white;
  padding: 2rem 2rem;
  position: relative;
}
.descKontakt .desc1::before,
.descKontakt .desc2::before {
  position: absolute;
  top: 5%;
  left: 0;
  content: "";
  width: 0.4rem;
  height: 90%;
  background-color: white;
  z-index: 1;
}
.desc1,
.desc2 {
  animation: heroDesc 0.5s forwards;
  transform: scaleY(0%);
  animation-delay: 0.3s;
  overflow: hidden;
}
.kontakt__Section .desc1 ul,
.kontakt__Section .desc2 ul {
  list-style: none;
  font-weight: 200;
}
.kontakt__Section .desc1 ul li,
.kontakt__Section .desc2 ul li {
  list-style: none;
  font-weight: 200;
  line-height: 1.2rem;
  animation: h1h2 1.5s forwards;
  animation-delay: 0.7s;
  transform: translateX(-200%);
}
.kontktBox {
  min-width: 500px;
  margin: 2%;
}
.mapa {
  transform: translateX(200%);
  animation: h1h3 1s forwards;
}
@keyframes heroDesc {
  0% {
    transform: scaleY(0%);
  }
  100% {
    transform: scaleY(100%);
  }
}
@keyframes h1h2 {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes h1h3 {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(0%);
  }
}
@media screen and (max-width: 1180px) {
  .kontakt__Section {
    height: calc(100vh - 104px);
  }
}
@media screen and (max-width: 1225px) {
  .mapa {
    width: 100%;
  }
  .mapa {
    min-width: unset;
  }
}
</style>
