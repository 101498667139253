<template>
  <div :class="{toggle: isOpen}" @click="toggle" class="burger">
    <span class="line"></span>
    <span class="line"></span>
    <span class="line"></span>
  </div>
</template>

<script>
export default {
  props:['isOpen'],
}
</script>

<style>
.burger{
  width: 25px;
  height: 15px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;
  transition: 0.3s all;
  overflow: visible;
}
.line{
  width: 25px;
  height: 2px;
  background-color: white;
  transition: 0.3s all;
}
.burger.toggle span:nth-child(1){
  transform: translateY(6px) rotate(45deg);
  transition-delay: 0.125s;
}
.burger.toggle span:nth-child(2){
  transform: translateX(150px);
}
.burger.toggle span:nth-child(3){
  transform: translateY(-7px) rotate(315deg);
  transition-delay: 0.25s;
}
</style>